<template>
  <div>
    <ui-component-drawer
      v-if="showSideDrawer"
      :drawerTitle="'Space summary'"
      :showDrawer="showSideDrawer"
      :onClickClose="hideDrawer"
      :drawerSize="'extra-large'"
      position="right"
    >
      <template v-slot:content>
        <SpaceSummary v-if="showSpaceSummary" :spaceId="itemId" />

        <Warnings v-if="showWarningsSummary" :warnings="warnings" />
      </template>
    </ui-component-drawer>

    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Space</th>
          <th>Meetingtype</th>
          <th>Check status</th>
          <th>Warnings</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody v-if="pSpaces.length">
        <tr v-for="(space, index) in pSpaces" :key="index">
          <td>
            <a @click="setShowSpaceSummary(space.Id)">{{ space.Name }}</a>
          </td>
          <td>{{ space.MeetingtypeId | getMeetingtypeName }}</td>
          <td>
            <span class="icon">
              <font-awesome-icon
                v-if="space.CheckStatus === 1"
                :icon="['fas', 'check']"
                class="has-text-success"
              />
              <font-awesome-icon
                v-if="space.CheckStatus === -1"
                :icon="['fas', 'times']"
                class="has-text-danger"
              />
            </span>
          </td>
          <td>
            <span class="icon">
              <font-awesome-icon
                v-if="space.CheckStatus === 1 && space.Warnings.length === 0"
                :icon="['fas', 'check']"
                class="has-text-success"
              />
              <font-awesome-icon
                v-if="space.Warnings.length > 0"
                :icon="['fas', 'times']"
                class="has-text-danger"
              />
            </span>
            <span v-if="space.Warnings.length > 0"
              >{{ space.Warnings.length }} Warnings</span
            >
          </td>
          <td width="75" class="has-text-right">
            <a @click="setShowWarningSummary(space.Warnings)">Show</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import spaceProvider from '@/providers/space'

export default {
  components: {
    SpaceSummary: () => import('@/components/Spaces/SpaceSummary'),
    Warnings: () => import('@/components/UI/Warnings'),
  },

  props: {},

  data() {
    return {
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      index: 0,
      pSpaces: [],
      itemId: 0,
      showSideDrawer: false,
      showSpaceSummary: false,
      warnings: [],
    }
  },

  computed: {
    ...mapState('spaceStore', ['spacesPage', 'spaces']),
  },

  created() {
    this.processSpaces()
  },

  methods: {
    processSpaces() {
      let spaces = []

      for (let i = 0; i < this.spaces.length; i++) {
        let space = this.spaces[i]

        for (let j = 0; j < space.Meetingtypes.length; j++) {
          let meetingtype = space.Meetingtypes[j]

          spaces.push({
            Id: space.Id,
            Name: space.Name,
            MeetingtypeId: meetingtype.MeetingtypeId,
            CheckStatus: -1,
            Warnings: [],
          })
        }
      }

      this.pSpaces = spaces

      this.checkSpaces()
    },

    checkSpaces() {
      let i = 0

      if (this.pSpaces && this.pSpaces.length > 0) {
        let space = this.pSpaces[this.index]
        if (space) {
          space.CheckStatus = 0

          spaceProvider.methods
            .checkSpace(
              this.channelId,
              this.locationId,
              space.Id,
              space.MeetingtypeId
            )
            .then((response) => {
              if (response.status === 200) {
                space.Warnings = response.data

                space.CheckStatus = 1
              }
            })
            .finally(() => {
              if (this.index < this.pSpaces.length) {
                this.index = this.index + 1
                this.checkSpaces()
              }
            })
        }
      }
    },

    setShowWarningSummary(warnings) {
      this.drawerTitle = 'Warnings'
      this.showSideDrawer = true
      this.showWarningsSummary = true
      this.warnings = warnings
    },

    setShowSpaceSummary(spaceId) {
      this.showSideDrawer = true
      this.showSpaceSummary = true
      this.itemId = spaceId
    },

    hideDrawer() {
      this.showSideDrawer = false
      this.showSpaceSummary = false
      this.itemId = 0
      this.warnings = []
    },
  },
}
</script>

<style></style>
